// Подключение свайпера
import Swiper from "swiper";
import { Navigation } from "swiper/modules";

new Swiper(".reviews-slider", {
  modules: [Navigation],
  breakpoints: {
    1560: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1260: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },
    1020: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    767: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1.1,
      spaceBetween: 14,
    },
  },
  loop: true,
  navigation: {
    nextEl: ".reviews__next",
    prevEl: ".reviews__prev",
  },
});

import AOS from "aos";
AOS.init({
  once: true,
  duration: 800,
  offset: -50,
});

import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
const inputs = document.querySelectorAll(".form-phone");
if (inputs.length > 0) {
  inputs.forEach((input) => {
    intlTelInput(input, {
      initialCountry: "auto",
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback("us"));
      },
      strictMode: true,
      separateDialCode: true,
      autoPlaceholder: "aggressive",
      countrySearch: false,
    });
  });
}
