import "./_components.js";
import "./_functions.js";
import "./components/review.js";
import "./components/sendForm.js";
import "./functions/burger.js";

const accs = document.querySelectorAll(".accordion");
if (accs.length > 0) {
  accs.forEach((acc) => {
    const button = acc.querySelector("button");
    const panel = acc.querySelector("p");
    if (acc) {
      button.addEventListener("click", () => {
        acc.classList.toggle("active");
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  });
}
const header = document.querySelector(".header");
if (header) {
  const activeScroll = () => {
    if (window.scrollY > 50) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  };
  window.addEventListener("scroll", function () {
    activeScroll();
  });
  activeScroll();
}
