// setTimeout(() => {
//   document.querySelector(".call").classList.add("show");
// }, 20000);
const openModal = (modal) => modal.classList.add("show");
const closeModal = (modal) => modal.classList.remove("show");
const reviewModal = document.querySelector(".review");
const reviewDone = document.querySelector(".review-send");
if (reviewModal && reviewDone) {
  const form = document.querySelector(".review-form");
  const closeDone = document.querySelector(".review-send__close");

  form.addEventListener("submit", function (event) {
    event.preventDefault();

    const name = form.querySelector('input[name="name"]').value.trim();
    const title = form.querySelector('input[name="title"]').value.trim();
    const comment = form.querySelector('textarea[name="comment"]').value.trim();
    const rating = form.querySelector('input[name="rating"]').value;

    if (name && title && comment && rating > 0) {
      setTimeout(() => {
        closeModal(reviewModal);
        setTimeout(() => {
          openModal(reviewDone);
        }, 1000);
      }, 1000);
    } else {
      const errorMessage = form.querySelector(".review__error");
      errorMessage.textContent = "Пожалуйста, заполните все обязательные поля.";
    }
  });
  closeDone.addEventListener("click", () => {
    closeModal(reviewDone);
  });
}
