const callModal = document.querySelector(".call");
const reviewModal = document.querySelector(".review");

if (callModal) {
  const callButton = document.querySelector(".hero__action");
  const callClose = document.querySelector(".call-form__close");
  callButton?.addEventListener("click", () => {
    callModal.classList.add("show");
  });
  callClose?.addEventListener("click", () => {
    callModal.classList.remove("show");
  });
}

if (reviewModal) {
  const reviewButton = document.querySelector(".reviews__button");
  const reviewClose = document.querySelector(".review-form__close");

  reviewButton?.addEventListener("click", () => {
    reviewModal.classList.add("show");
  });
  reviewClose?.addEventListener("click", () => {
    reviewModal.classList.remove("show");
  });
}
