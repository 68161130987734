setTimeout(() => {
  document.querySelector(".call")?.classList.add("show");
}, 15000);

const successModal = document.querySelector(".modal-success");
const closeModalButton = successModal?.querySelector(".modal-success__close");

const validateForm = (form) => {
  let isValid = true;
  const errorMessage = form.querySelector(".error-message");

  errorMessage.textContent = "";

  const name = form.querySelector('input[name="name"]').value.trim();
  const phone = form.querySelector('input[name="phone"]').value.trim();
  const problem = form.querySelector('textarea[name="problem"]').value.trim();

  if (!name || name.length < 2) {
    errorMessage.textContent = "Пожалуйста, введите ваше имя.";
    isValid = false;
  }

  if (!phone || phone.length < 5) {
    errorMessage.textContent = "Пожалуйста, введите номер телефона.";
    isValid = false;
  }

  if (!problem) {
    errorMessage.textContent = "Пожалуйста, опишите вашу проблему.";
    isValid = false;
  }

  return isValid;
};

const submitForm = async (form) => {
  const name = form.querySelector('input[name="name"]').value;
  const phone = form.querySelector('input[name="phone"]').value;
  const problem = form.querySelector('textarea[name="problem"]').value;

  const data = {
    name: name,
    phone: phone,
    problem: problem,
  };

  try {
    const response = await fetch("/api/leads", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Ошибка сети");
    }

    // const parent = form.closest(".modal-parent");
    // if (parent) {
    //   parent.classList.remove("show");
    //   successModal.classList.add("show");
    // }
    window.location.href = "success.html";

    form.reset();
  } catch (error) {
    console.error("Ошибка при отправке данных:", error);
  }
};

document.querySelectorAll(".form-modal").forEach((form) => {
  form.addEventListener("submit", (event) => {
    event.preventDefault();

    if (validateForm(form)) {
      submitForm(form);
    }
  });
});
closeModalButton?.addEventListener("click", () => {
  successModal.classList.remove("show");
});
